<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem" style="margin-right: 0" :to="{path: '/book'}">
          <span style="color: #909399;font-weight: normal;">书目检索</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item class="breaditem" style="margin-left: 0">
          <span style="color: #909399">{{mark}}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>
        <span class="span" style="color: #606266;margin-left: 30px">库存 </span>
        <span class="span" style="color: #409EFF">{{num}}</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="products" :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              style="width: 871px">
      <el-table-column label="供货商" width="350" :resizable="false">
        <template slot-scope="scope">
          <div style="float: left">
            <el-tag v-if="scope.row.seller.restdate!==0" type="warning"
                    style="margin-left: -11px">
              休息至 {{restFormat(scope.row.seller.restdate)}}
            </el-tag>
            <div>
              <span :style="{marginRight:'5px',color:(scope.row.seller.restdate!==0?'#C0C4CC':'#606266')}">
                {{scope.row.seller.name}}</span>
              <el-tag v-if="scope.row.seller.white" type="success">已关注</el-tag>
              <el-tag type="primary">{{getFormat(scope.row.seller.getmode)}}</el-tag>
              <el-tag type="danger">{{backFormat(scope.row.seller.backmode)}}</el-tag>
              <el-tag v-if="scope.row.seller.afternoon" type="info">下午也配</el-tag>
              <el-tag v-if="scope.row.seller.single" type="info">不留单本</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="seller.upddate" width="100" :resizable="false"
                       :formatter="timeFormat"/>
      <el-table-column label="库位" prop="position" width="150" :resizable="false"/>
      <el-table-column label="折扣" width="65" :resizable="false">
        <template slot-scope="scope">
          <div style="float: left">
            <span style="margin-right: 5px">{{discountFormat(scope.row.discount)}}</span>
            <el-tooltip v-if="scope.row.seller.cut!==undefined"
                        placement="top-start" effect="light" :open-delay="300">
              <span slot="content" style="color: #C0C4CC">同一订单内所有图书实洋</span>
              <br slot="content"/><br slot="content"/>
              <span slot="content" style="white-space: pre-wrap">{{scope.row.seller.cut}}</span>
              <el-tag type="success" style="cursor: default">惠</el-tag>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="售价" width="75" :resizable="false">
        <template slot-scope="scope">
          {{parseFloat(scope.row.discount*scope.row.book.price).toFixed(2)}}
        </template>
      </el-table-column>
      <el-table-column label="库存" prop="num" width="60" :resizable="false"/>
      <el-table-column label="加购" width="70" :resizable="false" header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-badge :value=scope.row.cartNum :max="99">
              <el-popover placement="right" v-model="scope.row.visible">
                <div style="display: flex;justify-content: center;align-items: center">
                  <el-input-number class="family1" v-model="scope.row.order" size="mini"
                                   :min="1" :max="scope.row.num"/>
                  <el-button class="buttonPopo" size="mini" type="success"
                             @click="addCart(scope.row)" icon="el-icon-check"
                             :loading="scope.row.loading"/>
                </div>
                <el-button slot="reference" class="buttonTable" size="mini" type="success"
                           icon="el-icon-shopping-cart-2" @click="initOrderNum(scope.row)"
                           :loading="scope.row.loading"/>
              </el-popover>
            </el-badge>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
  </div>
</template>

<script>
  export default {
    created () {
      this.query.bookId = sessionStorage.getItem('id')
      this.query.white = window.localStorage.getItem('white') === 'true'
      this.mark = sessionStorage.getItem('mark')
      if (this.mark === null) {
        this.mark = '供货商'
      }
      this.selectProduct()
    },
    data () {
      return {
        query: {
          bookId: null,
          white: null,
          page: 1
        },
        products: [],
        total: 0,
        num: 0,
        mark: ''
      }
    },
    methods: {
      getFormat (value) {
        if (value === '0') {
          return '自提'
        } else if (value === '1') {
          return '兴茂'
        } else if (value === '2') {
          return '代发'
        } else {
          return '书店宝'
        }
      },
      backFormat (value) {
        if (value === '0') {
          return '不可退'
        } else if (value === '1') {
          return '1天可退'
        } else if (value === '2') {
          return '3天可退'
        } else if (value === '3') {
          return '7天可退'
        } else if (value === '4') {
          return '15天可退'
        } else {
          return '30天可退'
        }
      },
      discountFormat (value) {
        return parseFloat(value).toFixed(2)
      },
      timeFormat (row) {
        const date = new Date(row.seller.upddate)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      initOrderNum (row) {
        row.order = 1
      },

      selectProduct () {
        this.query.page = 1
        this.getProductList()
      },
      pageChange (page) {
        this.query.page = page
        this.getProductList()
      },
      async getProductList () {
        if (this.query.bookId === null) {
          return
        }

        const loading = this.loading()
        try {
          const { data: res } = await this.$http.get('getProductList', { params: this.query })

          if (!this.isLogin(res)) {
            loading.close()
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.products = []
            loading.close()
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.num = res.data.count
          this.products = res.data.data
          this.products = this.products.map(item => {
            this.$set(item, 'order', 1)
            this.$set(item, 'visible', false)
            this.$set(item, 'loading', false)
            return item
          })
          loading.close()
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.products = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async addCart (row) {
        if (row.order === undefined) {
          this.$msg.warning('请输入订数！')
          return
        }
        this.$set(row, 'visible', false)
        this.$set(row, 'loading', true)

        try {
          const { data: res } = await this.$http.post('addCart',
            {
              bookId: row.book.id,
              sellerId: row.seller.id,
              num: row.order
            })

          if (!this.isLogin(res)) {
            this.$set(row, 'loading', false)
            return
          }

          this.$set(row, 'loading', false)
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            if (row.cartNum !== undefined) {
              row.cartNum += row.order
            } else {
              row.cartNum = row.order
            }
            this.$set(row, 'order', 1)
            this.$msg.success('加入成功！')
          }
        } catch (e) {
          this.$set(row, 'loading', false)
          this.$msg.error(this.netError)
        }
      },

      restFormat (time) {
        const date = new Date(time)
        const Y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return Y + '-' + MM + '-' + d + ' ' + h + ':' + m
      }
    }
  }
</script>

<style scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 32px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 5px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .mark {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 922px;
    height: 15px;
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    line-height: 15px;
  }

  .el-input-number {
    width: 100px !important;
  }

  .el-badge {
    position: absolute;
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-badge__content.is-fixed {
    transform: translateY(-45%) translateX(70%) !important;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }
</style>
